export enum AffiliateProductPartner {
  AMAZON = 'AMAZON',
  EBAY = 'EBAY',
}

export namespace AffiliateProductPartner {
  export function getAllAffiliateProductPartners(): AffiliateProductPartner[] {
    return [AffiliateProductPartner.AMAZON, AffiliateProductPartner.EBAY];
  }

  export function getDisplayName(partner?: AffiliateProductPartner): string {
    switch (partner) {
      case AffiliateProductPartner.AMAZON:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PARTNER.AMAZON';
      case AffiliateProductPartner.EBAY:
        return 'APP.AFFILIATE_PRODUCTS_PAGE_LIST.PARTNER.EBAY';
      default:
        return '';
    }
  }
}
