import { ClassData } from '@models/class-data';

export class EventCategory {
  id: number;
  createdAt?: Date;

  parentCategoryId?: number;

  name: string;
  title: string;
  subtitle: string;

  targetGroups?: number[];
  keywords?: number[];
  subCategories?: EventCategory[];

  constructor(json: ClassData<EventCategory>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    if (json.parentCategoryId !== undefined) {
      this.parentCategoryId = +json.parentCategoryId;
    }

    this.name = json.name;
    this.title = json.title;
    this.subtitle = json.subtitle;

    if (json.subCategories) {
      this.subCategories = this.mapSubCategories(json.subCategories);
    }
  }

  getTitleFragment(): string {
    return encodeURIComponent(this.title.toLowerCase().replace(/\s+/g, '-'));
  }

  private mapSubCategories(subCategories: EventCategory[]): EventCategory[] {
    return subCategories.map((it) => new EventCategory(it));
  }
}
